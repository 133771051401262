import { stringify } from 'query-string';
import API from "../../../Utils/API";

export const FETCH_DATA_TRAFFIC_PORT_START = 'FETCH_DATA_TRAFFIC_PORT_START';
export const FETCH_DATA_TRAFFIC_PORT_SUCCESS = 'FETCH_DATA_TRAFFIC_PORT_SUCCESS';
export const FETCH_DATA_TRAFFIC_PORT_ERROR = 'FETCH_DATA_TRAFFIC_PORT_ERROR';

export const FETCH_PORT_START = 'FETCH_PORT_START';
export const FETCH_PORT_SUCCESS = 'FETCH_PORT_SUCCESS';
export const FETCH_PORT_ERROR = 'FETCH_PORT_ERROR';

function fetchPortDataTrafficGraphStart(month = null, year = null) {
    return {
        type: FETCH_DATA_TRAFFIC_PORT_START,
        month,
        year
    }
}

function fetchPortDataTrafficGraphSuccess(data) {
    return {
        type: FETCH_DATA_TRAFFIC_PORT_SUCCESS,
        data
    }
}

function fetchPortDataTrafficGraphError(error) {
    return {
        type: FETCH_DATA_TRAFFIC_PORT_ERROR,
        error
    }
}

export function fetchPortDataTrafficGraph(month = null, year = null) {
    var params = {
        ...(month && { month }),
        ...(year && { year }),
    }

    return (dispatch) => {
        dispatch(fetchPortDataTrafficGraphStart(month, year));
        API.getData("/data-traffic/port?" + stringify(params))
            .then(PortDataTraffic => {
                dispatch(fetchPortDataTrafficGraphSuccess(PortDataTraffic));
                return PortDataTraffic;
            })
            .catch(error =>
                dispatch(fetchPortDataTrafficGraphError(error))
            );
    }
}