import * as React from "react";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useGetWhoamiQuery } from "../../../API";
import { fetchLogo } from "../../../Reducers/Client/Logo/Actions";

const Header = (props: any) => {
    const { Logo, dispatch } = props;

    const {data: User, isLoading} = useGetWhoamiQuery();

    if (User && !isLoading && User?.block && !User.admin) {
        //The user is blocked, redirect to the invoices page
        window.location.replace("/clientarea.php?action=invoices");
    }

    if (null == Logo.logo && !Logo.loading) {
        dispatch(fetchLogo());
    }

    return (
        <LinkContainer to="/dashboard">
            <Navbar.Brand className="brand">
                {Logo.logo != null && <img key={Logo.date} src={Logo.logo} />}
            </Navbar.Brand>
        </LinkContainer>
    );
};

const mapStateToProps = ({ Logo }: { Logo: any }) => ({
    Logo,
});

export default connect(mapStateToProps)(Header);
