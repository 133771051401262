import React, { useState } from "react";
import storeManager from "../Utils/StoreManager";
import { default as Terms } from "./Terms";
import { default as ContentReducers } from "../../Reducers/Content";
import { acceptTerms, fetchTerms } from "../../Reducers/Content/Terms/Actions";
import { connect } from "react-redux";
import { useGetWhoamiQuery } from "../../API";

const TermsSub = (props) => {
    var TermsData = props.Terms;
    let v = <></>;

    const {data: User, isLoading} = useGetWhoamiQuery();

    if (User) {
        if (TermsData.terms == null && !TermsData.loading && TermsData.error == null) {
            props.dispatch(fetchTerms());
        } else if (TermsData.loading) {
        } else if (TermsData.error) {
            v = TermsData.error;
        } else {
            let accepted = false;
            let acceptedLatest = false;
            let latest = null;
            let product = null;
            let productId = null;
            let fTerms = null;
            if (TermsData.terms.isArray) {
                TermsData.terms.forEach((t) => {
                    if (
                        (t.product == null && props.product == -1) ||
                        t.product?.internalName == props.product
                    ) {
                        accepted = t.accepted;
                        acceptedLatest = t.acceptedLatest;
                        latest = t.latest;
                        product = t.product;
                        fTerms = t;
                        if (t.product != null) {
                            productId = t.product.id;
                        } else if (props.product == -1) {
                            productId = -1;
                        }
                        if (!acceptedLatest) {
                            v = (
                                <Terms
                                    isAdmin={User.admin}
                                    text={latest.text}
                                    handleSubmit={(data, { setSubmitting }) => {
                                        if (User.admin) {
                                            return;
                                        }
                                        props.dispatch(
                                            acceptTerms(latest.id, productId, () => {
                                                setSubmitting(false);
                                            })
                                        );
                                    }}
                                />
                            );
                        }
                    }
                });
            }
            if (props.onRetrieve) {
                props.onRetrieve(fTerms, User.admin);
            }
        }
    }
    return v;
};
export default TermsView = (props) => {
    const { store } = storeManager;

    store.injectReducers(ContentReducers);

    let ts = connect((d) => {
        return {
            Terms: d.Terms,
        };
    })(TermsSub);
    return React.createElement(ts, props);
};
