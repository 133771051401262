import * as React from "react";
import SidebarNav from "./SidebarNav";
import Content from "./Content";

type Props = {
    children: React.ReactChild;
};

const SidebarLayout = (props: Props) => {
    return (
        <div className="page-layout">
            <SidebarNav />
            <Content />
        </div>
    );
};

export default SidebarLayout;
