import API from "../../../Utils/API";
import { stringify } from 'query-string';

export const FETCH_DATA_TRAFFIC_TOTAL_START   = 'FETCH_DATA_TRAFFIC_TOTAL_START';
export const FETCH_DATA_TRAFFIC_TOTAL_SUCCESS = 'FETCH_DATA_TRAFFIC_TOTAL_SUCCESS';
export const FETCH_DATA_TRAFFIC_TOTAL_ERROR   = 'FETCH_DATA_TRAFFIC_TOTAL_ERROR';

function fetchTotalDataTrafficGraphStart(month = null, year = null) {
    return {
        type: FETCH_DATA_TRAFFIC_TOTAL_START,
        month,
        year
    }
}

function fetchTotalDataTrafficGraphSuccess(data, percentile95) {
    return {
        type: FETCH_DATA_TRAFFIC_TOTAL_SUCCESS,
        data,
        percentile95
    }
}

function fetchTotalDataTrafficGraphError(error) {
    return {
        type: FETCH_DATA_TRAFFIC_TOTAL_ERROR,
        error
    }
}

export function fetchTotalDataTrafficGraph(month = null, year = null) {
    var params = {
        ...(month && {month}),
        ...(year && {year}),
    }

    return (dispatch) => {
        dispatch(fetchTotalDataTrafficGraphStart(month, year));
        API.getData("/data-traffic?"+stringify(params))
            .then(TotalDataTraffic => {
                API.getData("/data-traffic/95-percentile?"+stringify(params))
                .then(Percentile95 => {
                    dispatch(fetchTotalDataTrafficGraphSuccess(TotalDataTraffic, Percentile95[0]));
                    return TotalDataTraffic;
                })
                .catch(error =>
                    dispatch(fetchTotalDataTrafficGraphError(error))
                );
            })
            .catch(error =>
                dispatch(fetchTotalDataTrafficGraphError(error))
            );
    }
}