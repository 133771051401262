import {FETCH_DATA_TRAFFIC_TOTAL_START, FETCH_DATA_TRAFFIC_TOTAL_SUCCESS, FETCH_DATA_TRAFFIC_TOTAL_ERROR } from './Actions';

const initialState = {
    loading:        false,
    data:           null,
    percentile95:   null,
    error:          null,
    init:           false,
    month:          null,
    year:           null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_TRAFFIC_TOTAL_START:
            return {
                ...state,
                error:          null,
                loading:        true,
                init:           true,
                month:          action.month,
                year:           action.year
            }
        case FETCH_DATA_TRAFFIC_TOTAL_SUCCESS:
            return {
                ...state,
                loading:      false,
                data:         action.data,
                percentile95: action.percentile95
            }    
        case FETCH_DATA_TRAFFIC_TOTAL_ERROR:
            return {
                ...state,
                loading:    false,
                error:      action.error,
                loading:    false
            }
        default:
            return state;    
    }   
}