import React from "react";
import ReactDOM from "react-dom";
import Index from './Components/Pages';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './Components/Modal/AlertTemplate'
import '../fonts/montserrat-v14-latin-regular.woff'
import '../fonts/montserrat-v14-latin-800.woff'
import '../fonts/ReenieBeanie-Regular.ttf'
import '../css/style.scss'

//All redux stuff
import { Provider } from 'react-redux';
import storeManager from './Components/Utils/StoreManager'

const store = storeManager.configureStore();
const isDemo = process.env.DEMO === 'true';

if (process.env.sentryDSN) {
    Sentry.init({
        dsn: process.env.sentryDSN,
        environment: process.env.sentryENV ? process.env.sentryENV : "dev",
        integrations: [new Integrations.BrowserTracing()],
    });
}

async function enableMocking() {
    if (!isDemo) {
      return
    }
   
    const { worker } = await import('./Components/Pages/Demo/demo')
   
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
        onUnhandledRequest: 'bypass',
    })
}

enableMocking().then(() => {
    ReactDOM.render(
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <AlertProvider template={AlertTemplate} containerStyle={{zIndex: 999999, top: 70}} timeout={5000}>
                <Provider store={store}>
                    <Index />
                </Provider>
            </AlertProvider>
        </Sentry.ErrorBoundary>
        , document.getElementById("root")
    )
})

if (module.hot) {
    module.hot.accept('./Components/Pages/index.jsx', () => {
        const nextApp = require('./Components/Pages/index.jsx').default;
        renderApp(nextApp);
    })
}