import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { getIn } from "formik";

class ValidatedField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state.value = getIn(props, "values." + props.name);
  }

  static getErrorsFor = (name, errors) => {
    let nameparts = name.split(".");
    let err = errors;
    for (let i in nameparts) {
      err = err[nameparts[i]];
      if (err == undefined) {
        return false;
      }
      if (
        !(err instanceof Object || Array.isArray(err)) &&
        i != nameparts.length - 1
      ) {
        return false;
      }
    }

    return err;
  };

  componentWillReceiveProps(props) {
    this.state.value = getIn(props, "values." + props.name);
  }

  onChange(e) {
    if (this.props.onImmidiateChange) {
      this.props.onImmidiateChange(e);
    }
    if (this.props.onChange == undefined) {
      this.setState({ value: e.target.value });
    } else {
      this.props.onChange(e);
    }
  }
  onBlur(e) {
    if (this.props.setFieldValue != null) {
      this.props.setFieldValue(this.props.name, this.state.value, true);
    }
    this.props.onBlur(e);
  }

  render() {
    const name = this.props.name;
    const touched = this.props.touched;
    const errors = this.props.errors;
    let controlProps = {
      name: this.props.name,
      id: this.props.id,
      as: this.props.as,
      type: this.props.type,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onKeyPress: this.props.onKeyPress,
      onKeyDown: this.props.onKeyDown,
      defaultValue: this.props.defaultValue,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
      accept: this.props.accept,
      cols: this.props.cols,
      rows: this.props.rows,
      className: this.props.className,
      size: this.props.size
    };

    return (
      <React.Fragment>
        <Form.Control
          {...controlProps}
          value={this.state.value===null?"":this.state.value}
          isValid={this.props.isValid != undefined ? this.props.isValid : 
            getIn(touched, name) && !ValidatedField.getErrorsFor(name, errors)
          }
          isInvalid={this.props.isInvalid != undefined ? this.props.isInvalid : 
            getIn(touched, name) && ValidatedField.getErrorsFor(name, errors)
          }
        >
          {this.props.children}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {ValidatedField.getErrorsFor(name, errors)}
        </Form.Control.Feedback>
      </React.Fragment>
    );
  }
}

export default ValidatedField;
