import {FETCH_DATA_TRAFFIC_PORT_START, FETCH_DATA_TRAFFIC_PORT_SUCCESS, FETCH_DATA_TRAFFIC_PORT_ERROR, FETCH_PORT_START, FETCH_PORT_SUCCESS, FETCH_PORT_ERROR } from './Actions';

const initialState = {
    loading:        false,
    data:           null,
    error:          null,
    init:           false,
    month:          null,
    year:           null,
    port:           null,
    ports:          null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_TRAFFIC_PORT_START:
            return {
                ...state,
                error:          null,
                loading:        true,
                init:           true,
                month:          action.month,
                year:           action.year,
                port:           action.port,
            }
        case FETCH_DATA_TRAFFIC_PORT_SUCCESS:
            return {
                ...state,
                loading:      false,
                data:         action.data,
            }    
        case FETCH_DATA_TRAFFIC_PORT_ERROR:
            return {
                ...state,
                loading:    false,
                error:      action.error,
                loading:    false
            }
        case FETCH_PORT_START:
            return {
                ...state,
                error:          null,
                init:           true,
                loading:        true,
            }
        case FETCH_PORT_SUCCESS:
            return {
                ...state,
                loading:      false,
                ports:         action.data
            }    
        case FETCH_PORT_ERROR:
            return {
                ...state,
                loading:    false,
                error:      action.error
            }
        default:
            return state;    
    }   
}