import * as React from "react";
import TinySecurityNotifications from "../../TinySecurityNotifications";
import routes from "./routes";
import { Redirect, Route, Switch, useLocation } from "react-router";
import NotFound from "../../Pages/Errors/NotFound";
import { useGetWhoamiQuery } from "../../../API";
import TermsView from "../../Utils/TermsView";
import * as Sentry from '@sentry/react';
import Loader from "../../Utils/Loader";
import Warning2FA from "../../Utils/Warning2FA";
import Unavailable from "../../Pages/Demo/Unavailable";
import { Alert, Container } from "react-bootstrap";

type Props = {};

const AsyncAdmin = React.lazy(() => import("../../Pages/Admin"));

const isDemo = process.env.DEMO === 'true';

const Content = (props: Props) => {
    const [show, setShow] = React.useState(false);
    const { data: user, isLoading, error } = useGetWhoamiQuery();
    const {pathname} = useLocation();

    if (isLoading) return <Loader />;

    if (error) return <div>Could not fetch user.</div>;

    const isAdmin = user?.admin;
    const showUnavailable = isDemo && !pathname.startsWith('/ddos-protection');
    const showWarning = user?.require_2fa_setup && !user?.has_2fa && !isDemo;
    const showContent = !showWarning || isAdmin;

    return (
        <div id="content-scroll-container" className="content">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <TinySecurityNotifications />
                    {showWarning && <Warning2FA />}
                    {showUnavailable && <Unavailable />}
                    {user.id && (
                        <TermsView
                            product={-1}
                            onRetrieve={(terms: any, isAdmin: boolean) => {
                                if (show) return;
                                if (isAdmin) {
                                    setShow(true);
                                    return;
                                }
                                if (terms.acceptedLatest) {
                                    setShow(true);
                                    return;
                                }
                                if (terms.accepted && (terms.current.activeUntil ?? null) == null) {
                                    setShow(true);
                                    return;
                                }
                                if (
                                    terms.accepted &&
                                    new Date(terms.current.activeUntil) > new Date()
                                ) {
                                    setShow(true);
                                    return;
                                }
                                if ((terms.latest.activeFrom ?? null) == null) {
                                    setShow(true);
                                    return;
                                }
                                if (new Date(terms.latest.activeFrom) > new Date()) {
                                    setShow(true);
                                    return;
                                }
                            }}
                        />
                    )}
                    <React.Suspense fallback={<Loader />}>
                    <Switch>
                        {user.admin && <Route path="/ca2/admin" component={AsyncAdmin} />}
                        {show && showContent && !showUnavailable && user.id && (
                            <Route path="*">
                                <Switch>
                                    <Redirect exact from="/" to="/dashboard" />
                                    {routes.map((route, index) => {
                                        return (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                render={(props) => {
                                                    const Component = route.content;
                                                    return (
                                                        <Sentry.ErrorBoundary fallback={<Container><Alert variant="danger">An error has occurred while loading this page</Alert></Container>}>
                                                            <Component {...props}/>
                                                        </Sentry.ErrorBoundary>
                                                    )
                                                }}
                                            />
                                        );
                                    })}
                                    <Redirect exact from="/support-request" to="/support/request" />
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </Route>
                        )}
                        {/* {isAdmin && !clientID && (
                            <Route path="*">
                            <Container>
                            <Row>
                            <Col>
                            <h2>Not logged in as a user, only as admin</h2>
                            <LinkContainer to="/ca2/admin">
                            <Button>Admin panel</Button>
                            </LinkContainer>
                            </Col>
                            </Row>
                            </Container>
                            </Route>
                        )} */}
                    </Switch>
                    </React.Suspense>
                </>
            )}
        </div>
    );
};

export default Content;
