import * as React from "react";

const useOutsideClick = (refs: React.MutableRefObject<any>[], callback: () => any) => {
    React.useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            let clickedOnReference = false;
            for (const ref of refs) {
                if (ref.current && ref.current.contains(event.target)) {
                    clickedOnReference = true;
                }
            }
            if (!clickedOnReference) {
                if (typeof callback === "function") callback();
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [refs]);
};

export default useOutsideClick;
