import React, {useEffect, useState } from "react";
import { connect } from 'react-redux';
import { fetchSecurityNotifications, checkSecurityNotification, silenceSecurityNotification } from "../Reducers/TinySecurityNotifications/Actions";
import { Button, Row, Col, Container }  from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faPlus, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { default as Loader } from "./Utils/Loader";
import { Link } from "react-router-dom";
import InsertHTML from "./Utils/InsertHTML";
const TinySecurityNotifications = (props) => {
    let {  dispatch, TinySecurityNotifications } = props;
    
    const [componentState, setComponentState] = useState({
        showLoader:     false,
        checking:     {},
        silencing:     {},
        expanded:     {},
    });
    useEffect(() => {
        if ( !TinySecurityNotifications.loading && TinySecurityNotifications.securityNotifications == null && TinySecurityNotifications.error == null) {
            // no loader here, when there are no messages it should not be displayed.
            dispatch(fetchSecurityNotifications(1, TinySecurityNotifications.pageSize, TinySecurityNotifications.update));
        }
    });
    getPage = (page, sizePerPage) => {
        setComponentState({...componentState, showLoader: true})
        dispatch(fetchSecurityNotifications(page, sizePerPage, false, () => {
            setComponentState({...componentState, showLoader: false})
        }));
    } 
    check = (id) => {
        let s = componentState;
        s.checking[id] = true;
        setComponentState({...s});
        dispatch(checkSecurityNotification(id, () => {        
            let s = componentState;
            s.checking[id] = false;
            setComponentState({...s});
        }));
    } 
    silence = (id) => {
        let s = componentState;
        s.silencing[id] = true;
        setComponentState({...s});
        dispatch(silenceSecurityNotification(id, () => {        
            let s = componentState;
            s.silencing[id] = false;
            setComponentState({...s});
        }));
    } 

    expandToggle = (id) => {
        componentState.expanded[id] = !componentState.expanded[id];
        setComponentState({...componentState})
    }

    getClasses = (item) => {
        return "secnote level-"+item.notificationStatus.toLowerCase();
    }
    return (
        <>
            {componentState.showLoader && <Loader />}
            {TinySecurityNotifications.securityNotifications != undefined && TinySecurityNotifications.securityNotifications.length >= 0 && <>
                {(() => {
                    let l = [];
                   TinySecurityNotifications.securityNotifications.forEach((item, i) => {
                        l.push(<div key={"secnote_"+i} className={getClasses(item)}><Container>
                            <div>
                                <InsertHTML as="span" className="flex-grow-1">{item.descriptionText}</InsertHTML>
                                <div className="float-right">
                                    <span>
                                        {TinySecurityNotifications.total > 1 && <Link to="/waf/notifications">{TinySecurityNotifications.total-1} more</Link>}
                                    </span>
                                    <span className="pl-2">
                                        <Button title="Check" variant="link" size="sm" className="p-0 px-1 pb-0" onClick={() => check(item.id)}>
                                            <FontAwesomeIcon icon={faSync} />
                                            {componentState.checking[item.id] != undefined && componentState.checking[item.id] ? (
                                                <Loader />
                                            ) : (
                                                ""
                                            )}
                                        </Button>
                                        <Button title="Check" variant="link" size="sm" className="p-0 px-1 pb-0" onClick={() => expandToggle(item.id)}>   
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </span>
                                </div>
                            </div>
                            {componentState.expanded[item.id] != undefined && componentState.expanded[item.id] && <>
                                <Row className="mt-2">
                                    <InsertHTML as={Col} sm={12}>{item.descriptionDetailedText}</InsertHTML>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Button title="Silence" variant="info" size="sm" onClick={() => silence(item.id)}>
                                            <FontAwesomeIcon icon={faEyeSlash} /> Silence
                                            {componentState.silencing[item.id] != undefined && componentState.silencing[item.id] ? (
                                                <Loader />
                                            ) : (
                                                ""
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </>}
                        </Container></div>);
                    }) 
                    return l
                })()}
            </>}
        </>
    )
}

const mapStateToProps = ({TinySecurityNotifications}) => {
    return ({
        TinySecurityNotifications
    })
}

export default connect(mapStateToProps)(TinySecurityNotifications);
