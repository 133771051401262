import * as React from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import {
    faArrowRightArrowLeft,
    faBox,
    faCode,
    faCogs,
    faEnvelope,
    faInbox,
    faLock,
    faRightFromBracket,
    faUser,
    faUserSecret,
    faUsers,
    faImage,
    faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import MenuItem from "./MenuItem";
import { Dropdown } from "react-bootstrap";

type Props = {
    onBlur?: () => any;
};

const UserMenu = React.forwardRef((props: Props, ref: React.MutableRefObject<HTMLDivElement>) => {
    const menuRef = React.useRef(null);
    useOutsideClick([menuRef, ref], props.onBlur);
    return (
        <div className="user-menu" onClick={props.onBlur} ref={menuRef}>
            <MenuItem href="/clientarea.php?action=details" icon={faCogs} label="Account Details" />
            <MenuItem href="/index.php?rp=/account/users" icon={faUsers} label="User Management" />
            <MenuItem href="/index.php?rp=/account/contacts" icon={faEnvelope} label="Contacts" />
            <MenuItem href="/clientarea.php?action=emails" icon={faInbox} label="Email History" />
            <MenuItem to="/client/logo" icon={faImage} label="Logo Upload" />
            <MenuItem to="/client/api-credentials" icon={faCode} label="API Credentials" />
            <Dropdown.Divider />
            <MenuItem href="/clientarea.php?action=invoices" icon={faCreditCard} label="My Invoices" />
            <MenuItem href="/clientarea.php?action=services" icon={faBox} label="My Products" />
            <Dropdown.Divider />
            <MenuItem href="/index.php?rp=/user/profile" icon={faUser} label="Your Profile" />
            <MenuItem
                href="/clientarea.php?action=changepw"
                icon={faLock}
                label="Change Password"
            />
            <MenuItem
                href="/index.php?rp=/user/security"
                icon={faUserSecret}
                label="Security Settings"
            />
            <Dropdown.Divider />
            <MenuItem
                href="/index.php?rp=/user/accounts"
                icon={faArrowRightArrowLeft}
                label="Switch Account"
            />
            <MenuItem href="/logout.php" icon={faRightFromBracket} label="Logout" />
        </div>
    );
});

export default UserMenu;
