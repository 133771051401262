import Reducers from "../../Reducers";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { RestAPI } from "../../API";
import { errorListenerMiddelware } from "../Errors/ErrorListenerMiddleware";
import ErrorSlice from "../Errors/ErrorSlice";

// Configure the store
let storeManager = {
    store: null,
    configureStore() {
        this.store = createStore(
            createReducer(),
            composeWithDevTools(applyMiddleware(thunk, RestAPI.middleware, errorListenerMiddelware))
        );
        // Add a dictionary to keep track of the registered async reducers
        this.store.asyncReducers = {};
        // Create an inject reducer function
        // This function adds the async reducer, and creates a new combined reducer
        this.store.injectReducer = (key, asyncReducer) => {
            if (!key || this.store.asyncReducers[key]) {
                //Insert only once
                return;
            }
            this.store.asyncReducers[key] = asyncReducer;
            this.store.replaceReducer(createReducer(this.store.asyncReducers));
        };

        this.store.injectReducers = (asyncReducers) => {
            for (key in asyncReducers) {
                this.store.injectReducer(key, asyncReducers[key]);
            }
        };

        // Return the modified store
        return this.store;
    },
};

export default storeManager;

createReducer = (asyncReducers) => {
    return combineReducers({
        [RestAPI.reducerPath]: RestAPI.reducer,
        [ErrorSlice.name]: ErrorSlice.reducer,
        ...Reducers,
        ...asyncReducers,
    });
};
