import React from "react";
import { Modal } from "react-bootstrap";
import { default as TermsForm } from "../Form/Terms";
import InsertHTML from "./InsertHTML";

export default Terms = ( props ) => {
    const [showModal, setShowModal] = React.useState(true);

    return (
        <Modal show={showModal} onHide={() => {
            if (props.isAdmin) {
                setShowModal(false);
            }
        }}>
            <Modal.Header closeButton={props.isAdmin}>Terms</Modal.Header>
            <Modal.Body>
                <div className="terms-container">
                    <div className="terms-terms">
                        <InsertHTML className="terms-terms-inner text-field mb-3">{props.text}</InsertHTML>
                    </div>
                    <div className="terms-form-container">
                        <TermsForm isAdmin={props.isAdmin} handleSubmit={props.handleSubmit} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
