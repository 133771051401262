import React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

const Warning2FA = () => {
    const redirect = process.env.WHMCS_BASE_URL;

    return (
        <Container className="pt-3">
            <Row>
                <Col>
                    <Alert className="mt-2" variant="danger">
                        You need to set up Two Factor Authentication (2FA) for your account.
                        <br/>
                        <Button title="Set up 2FA" className="mt-2" href={redirect+"/user/security"}>
                            Set up 2FA
                        </Button>
                    </Alert>
                </Col>
            </Row>
        </Container>
    )
};

export default Warning2FA