import React from "react";
const Loader = (props) => {
    np = {...props}
    if (np.className){
        np.className = np.className + " loader"
    } else{
        np.className = "loader"
    }
    np.children = undefined
    np.duration = undefined
    np.timing = undefined
    np.width = undefined
    np.height = undefined
    np.fontSize = undefined
    np.radius = undefined
    np.style = {}

    let beamProps = {style:{}, className: "beam"}
    let innerProps = {style:{}, className: "inner"}
    if (props.duration){
        beamProps.style["animationDuration"] = props.duration
    }
    if (props.timing){
        beamProps.style["animationTimingFunction"] = props.timing
    }
    if (props.width){
        np.style["width"] = props.width
    }
    if (props.height){
        np.style["height"] = props.height
    }
    if (props.fontSize){
        innerProps.style["fontSize"] = props.fontSize
    }
    if (props.radius){
        np.style["borderRadius"] = props.radius
    }

    return (<>
        <div {...np}><div {...beamProps}></div><div {...innerProps}>{props.children}</div></div>
    </>)
}

export default Loader