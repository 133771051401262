import { FETCH_CONTENTSECURITYPOLICY_START, FETCH_CONTENTSECURITYPOLICYSETTINGS_START, FETCH_CONTENTSECURITYPOLICY_SUCCESS, FETCH_CONTENTSECURITYPOLICYSETTINGS_SUCCESS, FETCH_CONTENTSECURITYPOLICYSETTINGS_ERROR, FETCH_CONTENTSECURITYPOLICY_ERROR } from './Actions';

const initialState = {
    loading:                 false,
    settingsLoading:         false,
    policySettings: [],
    policy: {
        uuid: null,
        blockMixedContent: false,
        upgradeInsecureRequests: false
    },
    error:                   null,
    settingsError:           null,
    websiteUuid:             null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTENTSECURITYPOLICY_START:
            return {
                ...state,
                error:   null,
                loading: true,
                websiteUuid:    action.websiteUuid,
            }
        case FETCH_CONTENTSECURITYPOLICYSETTINGS_START:
            return {
                ...state,
                error:   null,
                settingsLoading: true,
                websiteUuid:    action.websiteUuid,
            }
        case FETCH_CONTENTSECURITYPOLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                policy: action.contentSecurityPolicy
            }    
        case FETCH_CONTENTSECURITYPOLICYSETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoading: false,
                policySettings: action.contentSecurityPolicySettings
            }
        case FETCH_CONTENTSECURITYPOLICY_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case FETCH_CONTENTSECURITYPOLICYSETTINGS_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                loading: false,
                settingsError: action.error
            }
        default:
            return state;    
    }
}