import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { setUnauthorized } from './ErrorSlice'

export const errorListenerMiddelware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && '401' == action.payload?.status) {       
        console.warn('User not logged in')
        api.dispatch(setUnauthorized(true))
    }

    return next(action)
}