import {FETCH_TERMS_START, FETCH_TERMS_ERROR, FETCH_TERMS_SUCCESS, INVALIDATE_TERMS} from './Actions';

const initialState = {
    loading: false,
    terms: null,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TERMS_START:
            return {
                ...state,
                error: null,
                loading: true,
            }
        case FETCH_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                terms: action.terms,
            }
        case FETCH_TERMS_ERROR:
            console.log(action.error);
            return {
                ...state,
                loading: false,
                error: action.error,
                terms: null
            }
        case INVALIDATE_TERMS:
            return initialState
        default:
            return state;
    }
}