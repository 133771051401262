import API from "../../Utils/API";

export const FETCH_SECURITYNOTIFICATIONS_START = 'FETCH_SECURITYNOTIFICATIONS_START';
export const FETCH_SECURITYNOTIFICATIONS_SUCCESS = 'FETCH_SECURITYNOTIFICATIONS_SUCCESS';
export const FETCH_SECURITYNOTIFICATIONS_ERROR = 'FETCH_SECURITYNOTIFICATIONS_ERROR';
export const INVALIDATE_SECURITYNOTIFICATIONS = 'INVALIDATE_SECURITYNOTIFICATIONS';

function fetchSecurityNotificationsStart(page, pageSize, filter) {
    return {
        type: FETCH_SECURITYNOTIFICATIONS_START,
        page, 
        pageSize, 
        filter, 
    }
}

function invalidate() {
    return {
        type: INVALIDATE_SECURITYNOTIFICATIONS,
    }
}

function fetchSecurityNotificationsSuccess(securityNotifications, total) {
    return {
        type: FETCH_SECURITYNOTIFICATIONS_SUCCESS,
        securityNotifications,
        total
    }
}

function fetchSecurityNotificationsError(error) {
    return {
        type: FETCH_SECURITYNOTIFICATIONS_ERROR,
        error
    }
}
export function fetchSecurityNotifications(page = 1, limit = 25, filter = {}, update = false, after = null) {
    if(filter.includeSilenced == undefined || filter.includeSilenced == null){
        filter.includeSilenced = true
    }
    if(filter.minStatus == undefined || filter.minStatus == null){
        filter.minStatus = "INFO"
    }
    let is = "true";
    if(!filter.includeSilenced){
        is = "false";
    }
    let u = "false";
    if(update){
        u = "true";
    }
    let wspart = "";
    if(filter.websiteID){
        wspart = "&websiteID="+filter.websiteID;
    }
    return (dispatch) => {
        dispatch(fetchSecurityNotificationsStart(page, limit, filter));
        API.getData("/security-notification?page="+page+"&limit="+limit+"&minStatus="+filter.minStatus+"&includeSilenced="+is+"&update="+u+wspart, {getTotalCount: true})
            .then(results => {
                results.results.then(securityNotifications => {
                    dispatch(fetchSecurityNotificationsSuccess(securityNotifications, results.total));
                    if(after != null){
                        after(securityNotifications)
                    }
                    return securityNotifications;
                })
            })
            .catch(error => {

                dispatch(fetchSecurityNotificationsError(error.message))
                if(after != null){
                    after([])
                }
            }
            );    
    }
}
export function checkSecurityNotification(id, after) {
    return (dispatch) => {
        API.patchData("/security-notification/"+id+"/check")
            .then(securityNotification => {
                dispatch(invalidate());
                after();
                return securityNotification;
            })
            .catch(error => {
                    dispatch(fetchSecurityNotificationsError(error.message))
                }
            );    
    }
}
export function silenceSecurityNotification(id, after) {
    return (dispatch) => {
        API.patchData("/security-notification/"+id+"/silence")
            .then(securityNotification => {
                dispatch(invalidate());
                after();
                return securityNotification;
            })
            .catch(error =>
                dispatch(fetchSecurityNotificationsError(error.message))
            );    
    }
}
export function unsilenceSecurityNotification(id, after) {
    return (dispatch) => {
        API.patchData("/security-notification/"+id+"/unsilence")
            .then(securityNotification => {
                dispatch(invalidate());
                after();
                return securityNotification;
            })
            .catch(error =>
                dispatch(fetchSecurityNotificationsError(error.message))
            );    
    }
}

