import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { addMethods } from "../../Utils/YupMethods";
import Loader from "../Utils/Loader";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import SidebarLayout from "../Layout/Navigation/SidebarLayout";
import './../../../css/toolbar-nav.scss'
import { useGetWhoamiQuery } from "../../API";
import { useDispatch, useSelector } from "react-redux";
import { getUnauthorized, setUnauthorized } from "../Errors/ErrorSlice";

const isDemo = process.env.DEMO === 'true';

const Index = () => {
    addMethods();

    const dispatch = useDispatch();
    const {data: User, isLoading, error} = useGetWhoamiQuery();
    const isUnauthorized = useSelector(getUnauthorized);
    const redirect = process.env.WHMCS_BASE_URL;
    const matomoUrl = process.env.MATOMO_TAG_MANAGER_CONTAINER_URL;

    const AdminNav = React.lazy(() => import('./Admin/AdminNav'));
    const DemoNav = React.lazy(() => import('./Demo/DemoNav'));

    React.useEffect(() => {
        if (matomoUrl) {
            //Matomo analytics code
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=matomoUrl; s.parentNode.insertBefore(g,s);
        }
    }, [])

    const authModal = (
        <Modal
            show={isUnauthorized}
            onHide={() => dispatch(setUnauthorized(false))}
        >
            <Modal.Header className="bg-primary text-white" closeButton>
                <Modal.Title>Not logged in</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                It looks like you are not logged in (anymore)
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => dispatch(setUnauthorized(false))}>Close</Button>
                {redirect && (
                    <Button href={redirect}>Log in</Button>
                )}
            </Modal.Footer>
        </Modal>
    );

    if (isLoading) {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <Loader />
                    </Col>
                </Row>
            </div>
        );
    }

    if (error) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert className="mt-2" variant="danger">Failed to load the user</Alert>
                    </Col>
                </Row>
                {authModal}
            </Container>
        )
    }

    const isAdmin = User?.admin;

    return (
        <Router>
            <React.Suspense fallback={<Loader />}>
            <div className="toolbar-nav-wrapper">
                {isAdmin && !isDemo && <AdminNav />}
                {isDemo && <DemoNav />}
                <div id="mobile-content-scroll-container">
                    <SidebarLayout />
                </div>
            </div>
            </React.Suspense>
            {authModal}
        </Router>
    );
}

export default Index
