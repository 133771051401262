import * as Sentry from '@sentry/react';
import { APIError } from '../Components/Errors/APIError';
import qs from 'qs';
import storeManager from "../Components/Utils/StoreManager";
import { setUnauthorized } from '../Components/Errors/ErrorSlice';
import { removeTrailingSlash } from './Formatter';

export default {
    getData(endpoint, resultOptions) {
        return call(endpoint, null, resultOptions);
    },

    postData(endpoint, data, options) {
        var headers
        var body
        
        if (options !== undefined && true == options.multipart) {
            headers= {
                Accept: "application/json"
            }
            body = new FormData();
            Object.keys(data).map((key) => {
                body.append(key, data[key])
            })

        } else if (options !== undefined && true == options.json) {
            headers = {
                Accept: "application/json",
                'Content-Type': "application/json"
            }
            body = JSON.stringify(data);
        } else {
            headers = {
                Accept: "application/json",
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            body = qs.stringify(data, {arrayFormat: 'brackets'})
        }
        return call(endpoint, {
            method: "POST",
            headers: headers,
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin",
            body: body
        });
    },

    putData(endpoint, data, options) { 
        var body;
        var headers;
        if (options !== undefined && true == options.json) {
            headers = {
                Accept: "application/json",
                'Content-Type': "application/json"
            }
            body = JSON.stringify(data);
        } else{
            headers = {
                Accept: "application/json",
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            body = qs.stringify(data, {arrayFormat: 'brackets'})
        }
        return call(endpoint, {
            method: "PUT",
            headers: headers,
            body: body
        });
    },

    patchData(endpoint, data, options) {
        if (options !== undefined && true == options.json) {
            headers = {
                Accept: "application/json",
                'Content-Type': "application/json"
            }
            body = JSON.stringify(data);
        } else{
            headers = {
                Accept: "application/json",
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            body = qs.stringify(data, {arrayFormat: 'brackets'})
        }
        return call(endpoint, {
            method: 'PATCH',
            headers: headers,
            body: body
        });
    },

    deleteData(endpoint, data) {
        return call(endpoint, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: qs.stringify(data, {arrayFormat: 'brackets'})
        });
    }
};

async function call(endpoint, options, resultOptions) {
    url = removeTrailingSlash(process.env.API_URL) + endpoint;

    return fetch(url, options)
        .then(handleErrors)
        .then(response => {
            if (response.status == 204) {
                return null;
            }
            if (resultOptions !== undefined && true == resultOptions.getTotalCount) {
                return {
                    'results': response.json(),
                    'total': parseInt(response.headers.get('X-Total-Count'))
                }
            }
            if (resultOptions === undefined || undefined === resultOptions.parseJson || true == resultOptions.parseJson) {
                return response.json();
            } else {
                return response;
            }
        });
}

async function handleErrors(response) {
    if (!response.ok) {
        if (401 == response.status) {
            console.warn('User not logged in')
            storeManager.store.dispatch(setUnauthorized(true))
        }
        let errorMsg = response.statusText;
        var responseCopy = response.clone();
        await response.json().then(body => {
            if (undefined != body.error) {
                if (body.error instanceof Object) {
                    errorMsg = body.error.message;
                } else {
                    errorMsg = body.error;
                }
            } else if (undefined != body.message) {
                errorMsg = body.message;
            } else {
                errorMsg = body;
            }
        }).catch(e => {
            Sentry.captureException(new Error(e));
            if (e instanceof SyntaxError) {
                // The response isn't a json, so handle it as text
                responseCopy.text().then(text => {
                    // If the text is to long 
                    // it probably is an entire HTML document 
                    // and not a simple message
                    if (text.length < 50) {
                        errorMsg = text;
                    }
                })
            }
        });
        throw new APIError(errorMsg, response.status, response.headers);
    }
    return response;
}
