import API from "../../../Utils/API";
import serveriusLogo from './../../../../img/serverius-colour.svg';

export const FETCH_LOGO_START = 'FETCH_LOGO_START';
export const FETCH_LOGO_DONE = 'FETCH_LOGO_DONE';

function fetchLogoStart() {
    return {
        type: FETCH_LOGO_START,
    }
}

function fetchLogoDone(logo, custom) {
    return {
        type: FETCH_LOGO_DONE,
        custom,
        logo
    }
}

export function fetchLogo() {
    return (dispatch) => {
        dispatch(fetchLogoStart());
        API.getData("/user/custom-logo?" + new Date().getTime(), {parseJson: false})
            .then(logo => {
                dispatch(fetchLogoDone(logo.url, true));
                return logo.url;
            })
            .catch(error => {
                dispatch(fetchLogoDone(serveriusLogo, false));
                return serveriusLogo;
            });
    }
}

export function updateLogo(data, success, fail) {
    return (dispatch) => {
        API.postData("/user/custom-logo", data, {
            multipart: true
        })
        .then( () => {
            dispatch(fetchLogo())
            success()
        })
        .catch(error => {
            fail(error)
        });
    }
}

export function deleteLogo(success, fail) {
    return (dispatch) => {
        API.deleteData("/user/custom-logo")
        .then( () => {
            dispatch(fetchLogo())
            success()   
        })
        .catch(error => {
            fail(error)
        });
    }
}
