import React from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik} from "formik";
import * as Yup from "yup";
import { default as Loader } from "../Utils/Loader";
import ValidatedField from "./Fields/ValidatedField";


export default TermsForm = (props) => {
    var defaults = {
        accepted: false,
    }
    var initValues = defaults

    const schema = Yup.object({
        accepted: Yup.bool().oneOf([true], 'The terms must be accepted to continue'),
    })
 
    return (
        <>
            <Formik
                initialValues={initValues}
                validationSchema={schema}
                onSubmit={props.handleSubmit}
            >
                {(form) => (
                <Form noValidate onSubmit={form.handleSubmit} className="terms-form">
                    {form.status && form.status.alertMsg && (
                        <Alert variant="danger">{form.status.alertMsg}</Alert>
                    )}
                    <Form.Group>
                        <ValidatedField
                            name="accepted"
                            type="checkbox"
                            values={form.values}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            errors={form.errors}
                            touched={form.touched}
                            size="sm"
                            className="checkbox-sm"
                            id="accepted"
                        >
                        </ValidatedField>
                        <Form.Label className="terms-label" htmlFor="accepted">
                            I have read, understood, and agree to the terms and conditions
                        </Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Button size="sm" type="submit" disabled={(!form.values.accepted||props.isAdmin)||form.isSubmitting}>
                            Accept{" "}
                            {form.isSubmitting ? (
                                <Loader />
                            ) : (
                                ""
                            )}
                        </Button>
                    </Form.Group>
                </Form>
                )}
            </Formik>
        </>
    );
}
