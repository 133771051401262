import { FETCH_CONTENTSECURITYPOLICYSETTING_START, FETCH_CONTENTSECURITYPOLICYSOURCES_START, FETCH_CONTENTSECURITYPOLICYSETTING_SUCCESS, FETCH_CONTENTSECURITYPOLICYSOURCES_SUCCESS, FETCH_CONTENTSECURITYPOLICYSETTING_ERROR , FETCH_CONTENTSECURITYPOLICYSOURCES_ERROR } from './Actions';

const initialState = {
    loading:                false,
    sourcesLoading:         false,
    policySources:          [],
    policySetting:          {

    },
    error:                  null,
    sourcesError:           null,
    websiteUuid:            null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTENTSECURITYPOLICYSETTING_START:
            return {
                ...state,
                error:   null,
                loading: true,
                websiteUuid:    action.websiteUuid,
                settingUuid:    action.settingUuid,
            }
        case FETCH_CONTENTSECURITYPOLICYSOURCES_START:
            return {
                ...state,
                error:   null,
                sourcesLoading: true,
                websiteUuid:    action.websiteUuid,
                settingUuid:    action.settingUuid,
            }
        case FETCH_CONTENTSECURITYPOLICYSETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                policySetting: action.contentSecurityPolicySetting
            }
        case FETCH_CONTENTSECURITYPOLICYSOURCES_SUCCESS:
            return {
                ...state,
                sourcesLoading: false,
                policySources: action.contentSecurityPolicySources
            }    
        case FETCH_CONTENTSECURITYPOLICYSETTING_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case FETCH_CONTENTSECURITYPOLICYSOURCES_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                sourcesLoading: false,
                error: action.error
            }
        default:
            return state;    
    }
}