import API from "../../Utils/API";

export const FETCH_CONTENTSECURITYPOLICY_START = 'FETCH_CONTENTSECURITYPOLICY_START';
export const FETCH_CONTENTSECURITYPOLICYSETTINGS_START = 'FETCH_CONTENTSECURITYPOLICYSETTINGS_START';
export const FETCH_CONTENTSECURITYPOLICY_SUCCESS = 'FETCH_CONTENTSECURITYPOLICY_SUCCESS';
export const FETCH_CONTENTSECURITYPOLICYSETTINGS_SUCCESS = 'FETCH_CONTENTSECURITYPOLICYSETTINGS_SUCCESS';
export const FETCH_CONTENTSECURITYPOLICY_ERROR = 'FETCH_CONTENTSECURITYPOLICY_ERROR';
export const FETCH_CONTENTSECURITYPOLICYSETTINGS_ERROR = 'FETCH_CONTENTSECURITYPOLICY_ERROR';

function fetchContentSecurityPolicyStart(websiteUuid) {
    return {
        type: FETCH_CONTENTSECURITYPOLICY_START,
        websiteUuid
    }
}
function fetchContentSecurityPolicySettingsStart(websiteUuid) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTINGS_START,
        websiteUuid
    }
}

function fetchContentSecurityPolicySuccess(contentSecurityPolicy) {
    return {
        type: FETCH_CONTENTSECURITYPOLICY_SUCCESS,
        contentSecurityPolicy
    }
}
function fetchContentSecurityPolicySettingsSuccess(contentSecurityPolicySettings) {

    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTINGS_SUCCESS,
        contentSecurityPolicySettings
    }
}

function fetchContentSecurityPolicyError(error) {
    return {
        type: FETCH_CONTENTSECURITYPOLICY_ERROR,
        error
    }
}
function fetchContentSecurityPolicySettingsError(error) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTINGS_ERROR,
        error
    }
}

export function fetchContentSecurityPolicySettings(websiteUuid) {
    return (dispatch) => {
        dispatch(fetchContentSecurityPolicySettingsStart(websiteUuid));
        API.getData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting")
            .then(contentSecurityPolicySettings => {
                dispatch(fetchContentSecurityPolicySettingsSuccess(contentSecurityPolicySettings));
                return contentSecurityPolicySettings;
            })
            .catch(error =>
                dispatch(fetchContentSecurityPolicySettingsError(error.message))
            );    
    }
}

export function fetchContentSecurityPolicy(websiteUuid) {
    return (dispatch) => {
        dispatch(fetchContentSecurityPolicyStart(websiteUuid));
        API.getData("/waf/v2/website/"+websiteUuid+"/content-security-policy")
            .then(contentSecurityPolicy => {
                dispatch(fetchContentSecurityPolicySuccess(contentSecurityPolicy));
                return contentSecurityPolicy;
            })
            .catch(error =>
                dispatch(fetchContentSecurityPolicyError(error.message))
            );    
    }
}

export function editContentSecurityPolicy(websiteUuid, data, success, fail) {
    return (dispatch) => {
        delete data.uuid;
        API.putData("/waf/v2/website/"+websiteUuid+"/content-security-policy", data)
            .then(res => {
                dispatch(fetchContentSecurityPolicy(websiteUuid));
                success();
            })
            .catch(error => {
                fail(error);
            });
    }
}
export function createContentSecurityPolicySetting(websiteUuid, data, success, fail) {

    let contentSecurityPolicy = data.contentSecurityPolicy;

    delete data.ContentSecurityPolicy;
    return (dispatch) => {
        API.postData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting", data)
            .then(res => {
                data.contentSecurityPolicy = contentSecurityPolicy
                dispatch(fetchContentSecurityPolicySettings(websiteUuid));
                success();
            })
            .catch(error => {
                data.contentSecurityPolicy = contentSecurityPolicy
                fail(error);
            });
    }
}
export function createContentSecurityPolicySimpleSetting(websiteUuid, data, success, fail) {
    let base = {...data}
    delete base.blocking
    delete base.reporting
    let blocking = {...base, mode: data.blocking, reportOnly: false}
    let reporting = {...base, mode: data.reporting, reportOnly: true}

    return (dispatch) => {
        (async ()=>{ 
            let errors = [];
            let reportingcall = API.postData("/waf/v2/website/"+websiteUuid+"/content-security-policy/simple", reporting)
                .catch(error => {
                    errors.push(error.message)
                })
            ;
            let blockingcall = API.postData("/waf/v2/website/"+websiteUuid+"/content-security-policy/simple", blocking)
                .catch(error => {
                    errors.push(error.message)
                })
            ;
            await reportingcall
            await blockingcall
            if(errors.length > 0){
                fail({message: errors.join(',')}) 
            }else{

                dispatch(fetchContentSecurityPolicySettings(websiteUuid));
                success();
            }
        })()

    }
}



export function deleteContentSecurityPolicySetting(websiteUuid, uuid, fail) {
    return (dispatch) => {
        API.deleteData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+uuid)
            .then(() => dispatch(fetchContentSecurityPolicySettings(websiteUuid)))
            .catch(e => {
                fail('Unable to delete this setting');
            });
    }
}