import * as React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faRocket, faPersonDigging, faPhone, faShieldHalved } from '@fortawesome/free-solid-svg-icons';

const Unavailable = () => {
    const history = useHistory();
    const {pathname} = useLocation();
    const isDashboard = pathname.startsWith('/dashboard') || pathname === '/';

    if (isDashboard) {
        return (
            <Container className="pt-3">
                <Row className="justify-content-center">
                    <Col xs={12} md={10} lg={8} xl={6}>
                        <Alert className="mt-2 text-center" variant="info">
                            <FontAwesomeIcon icon={faRocket} size={'6x'} className="m-4" />
                            <br/>
                            <strong>Welcome to the Serverius Client Panel Demo!</strong>
                            <br/>
                            <br/>
                            We're excited to have you here. Please note that not all features are currently available in this demo, but we hope you'll still find the experience insightful.
                            <br/>
                            <br/>
                            For detailed information about our client panel and its functionalities, please get in touch with our dedicated sales representatives. They'll provide you with comprehensive insights and answer any queries you may have.
                            <br/>
                            <br/>
                            Enjoy exploring!
                            <br/>
                            <div className="flex m-4">
                                <Button title="Continue to DDoS Protection" className="mr-3" onClick={() => history.push('/ddos-protection')}>
                                    <FontAwesomeIcon icon={faShieldHalved} className="mr-3" />
                                    Continue to DDoS Protection
                                </Button>
                                <Button title="Contact sales" href={"https://serverius.net/contact/"} target="_blank">
                                    <FontAwesomeIcon icon={faPhone} className="mr-3" />
                                    Contact sales
                                </Button>
                            </div>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className="pt-3">
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8} xl={6}>
                    <Alert className="mt-2 text-center" variant="info">
                        <FontAwesomeIcon icon={faPersonDigging} size={'6x'} className="m-4" />
                        <br/>
                        <strong>Apologies for the inconvenience!</strong>
                        <br/>
                        <br/>
                        The section you're trying to access is currently unavailable in this demo environment. We're diligently working to enhance our demo capabilities.
                        <br/>
                        <br/>
                        For detailed information about this section and its functionalities, please get in touch with our dedicated sales representatives. They'll provide you with comprehensive insights and answer any queries you may have.
                        <br/>
                        <br/>
                        Thank you for your patience and interest in our product!
                        <br/>
                        <div className="flex m-4">
                            <Button title="Return to previous page" className="mr-3" onClick={() => history.goBack()}>
                                <FontAwesomeIcon icon={faAngleLeft} className="mr-3" />
                                Return to previous page
                            </Button>
                            <Button title="Contact sales" href={"https://serverius.net/contact/"} target="_blank">
                                <FontAwesomeIcon icon={faPhone} className="mr-3" />
                                Contact sales
                            </Button>
                        </div>
                    </Alert>
                </Col>
            </Row>
        </Container>
    )
};

export default Unavailable