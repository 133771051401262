import * as React from "react";
import MenuItem from "./MenuItem";
import {
    faArrowRightArrowLeft,
    faHeadset,
    faServer,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import wafLogo from "../../../../img/brick_wall.svg";
import { useLocation } from "react-router";
import Header from "./Header";
import SubMenu from "./SubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useGetWhoamiQuery } from "../../../API";
import Loader from "../../Utils/Loader";

const isDemo = process.env.DEMO === 'true';

const SidebarNav = () => {
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [subMenuOpen, setSubMenuOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const {pathname} = useLocation();

    const {data: User, isLoading} = useGetWhoamiQuery();

    React.useEffect(() => {
        //Close the menu when the route changes, so when a user clicks a link in the menu
        setMenuOpen(false);
    }, [ pathname ]);

    const buttonRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className={"sidebar active" + (menuOpen ? " open" : "")}>
            <div className="header">
                <Header />
                <Button variant="link" className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </Button>
            </div>
            <div className="main-nav">
                <div className={"nav-content" + (subMenuOpen ? " expanded" : "")}>
                    <div className="main-menu">
                        <div>
                            <MenuItem to="/colocation" label="Colocation" icon={faServer} />
                            <MenuItem
                                to="/connectivity"
                                label="Connectivity"
                                icon={faArrowRightArrowLeft}
                            />
                            <MenuItem to="/ddos-protection" label="DDoS Protection" icon={faShieldAlt} />
                            <MenuItem to="/waf" label="WAF" icon={wafLogo} />
                            <MenuItem to="/storage" label="Storage" icon={faDatabase} />
                        </div>
                        <div>
                            <MenuItem
                                to="/support/request"
                                label="Support"
                                isActiveLocations={["/support"]}
                                icon={faHeadset}
                            />
                            {isLoading ? <Loader /> : (
                                <MenuItem
                                    icon={faUser}
                                    label={User?.firstname ?? "User"}
                                    ref={buttonRef}
                                    className={"nowrap " + (userMenuOpen ? "active " : "") + (isDemo ? "disabled " : "")}
                                    onClick={() => {
                                        if (!isDemo) {
                                            setUserMenuOpen(!userMenuOpen);
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <SubMenu
                        onBlur={() => {
                            setUserMenuOpen(false);
                        }}
                        ref={buttonRef}
                        userMenuOpen={userMenuOpen}
                        openSubMenu={setSubMenuOpen}
                    />
                </div>
                <div className="footer">
                    © {new Date().getFullYear()} Serverius B.V.
                    <br />
                    <div className="bottom-text">
                        <span>All rights reserved.</span>
                        <span>&#183;</span>
                        <a className="link" href="https://serverius.net/terms-and-conditions/">
                            General terms
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarNav;
