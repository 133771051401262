
import * as React from 'react'
import * as DOMPurify from 'dompurify'


const InsertHTML = (props: {children : string, as?: React.ElementType; [x:string]: any;}) => {
    const {as, children, ...rest} = props;

    return React.createElement(as ?? "div", {
        ...rest,
        dangerouslySetInnerHTML: {__html: DOMPurify.sanitize(children)}
        
    })
}

export default InsertHTML