

const initialState = {
    websiteUuid:            null,
    settingUuid:            null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;    
    }
}