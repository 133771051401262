import {FETCH_LOGO_START, FETCH_LOGO_DONE} from './Actions';

const initialState = {
    loading: false,
    logo: null,
    custom: false,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOGO_START:
            return {
                ...state,
                error: null,
                loading: true,
            }
        case FETCH_LOGO_DONE:
            return {
                ...state,
                loading: false,
                custom: action.custom,
                logo: action.logo,
            }
        default:
            return state;
    }
}