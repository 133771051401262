import * as React from "react";
import { RouteComponentProps } from "react-router";

type route = {
    path: string;
    exact?: boolean;
    sidebar?: (React.ComponentType<RouteComponentProps> & React.LazyExoticComponent<(props: any) => JSX.Element>) | JSX.Element;
    content: React.LazyExoticComponent<(props: any) => JSX.Element>;
};

const routes: route[] = [
    {
        path: "/dashboard",
        exact: true,
        content: React.lazy(() => import("../../Pages/Dashboard")),
    },
    {
        path: "/colocation",
        sidebar: React.lazy( () => import("../../Pages/Colo/Sidebar")),
        content: React.lazy( () => import("../../Pages/Colo")),
    },
    {
        path: "/connectivity",
        sidebar: React.lazy( () => import("../../Pages/Connectivity/Sidebar")),
        content: React.lazy( () => import("../../Pages/Connectivity")),
    },
    {
        path: "/waf",
        sidebar: React.lazy( () => import("../../Pages/WAF/Sidebar")),
        content: React.lazy( () => import("../../Pages/WAF")),
    },
    {
        path: "/storage",
        sidebar: React.lazy( () => import("../../Pages/Storage/Sidebar")),
        content: React.lazy( () => import("../../Pages/Storage")),
    },
    {
        path: "/ddos-protection",
        sidebar: React.lazy( () => import("../../Pages/DDOS/Components/Sidebar")),
        content: React.lazy( () => import("../../Pages/DDOS")),
    },
    {
        path: "/support",
        sidebar: React.lazy( () => import("../../Pages/Support/Sidebar")),
        content: React.lazy( () => import("../../Pages/Support")),
    },
    {
        path: "/client",
        sidebar: React.lazy( () => import("../../Pages/Client/Sidebar")),
        content: React.lazy( () => import("../../Pages/Client")),
    }
];

export default routes;
