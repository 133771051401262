import API from "../../../Utils/API";

export const FETCH_TERMS_START = 'FETCH_TERMS_START';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_TERMS_ERROR = 'FETCH_TERMS_ERROR';
export const INVALIDATE_TERMS = 'INVALIDATE_TERMS';

function fetchTermsStart() {
    return {
        type: FETCH_TERMS_START,
    }
}

function fetchTermsSuccess(terms) {
    return {
        type: FETCH_TERMS_SUCCESS,
        terms
    }
}

function fetchTermsError(error) {
    return {
        type: FETCH_TERMS_ERROR,
        error
    }
}
function invalidateTerms() {
    return {
        type: INVALIDATE_TERMS,
    }
}

export function fetchTerms(onSuccess) {
    return (dispatch) => {
        dispatch(fetchTermsStart());
        API.getData("/content/terms/status")
            .then(terms => {
                dispatch(fetchTermsSuccess(terms));
                if(onSuccess){
                    onSuccess(terms)
                }
                return terms;
            })
            .catch(error =>
                dispatch(fetchTermsError(error.message))
            );
    }
}
export function acceptTerms(id, productid, after) {
    return (dispatch) => {
        API.patchData("/content/terms/latest/"+id+"/product/"+productid+"/accept")
            .then(terms => {
                dispatch(invalidateTerms());
                if(after){
                    after()
                }
                return terms;
            })
            .catch(error => {
                dispatch(invalidateTerms());
                if(after){
                    after()
                }
            });
    }
}