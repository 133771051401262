import * as React from "react";
import { IconDefinition, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactSVG } from "react-svg";
import { match, useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { ExternalLink, InternalLink, OnClick } from "./types/Link";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import { EndIcon, ExtraAction } from "./types/End";

type Props = {
    icon?: string | IconDefinition;
    label: string;
    className?: string;
    active?: boolean;
    isActive?: (match: any, location: any) => boolean;
    isActiveLocations?: string[];
    exact?: true;
} & (InternalLink | ExternalLink | OnClick) &
    (EndIcon | ExtraAction);

const regexBuilder = (arr: string[]) => {
    let string = "^(";
    string += arr.map((item) => item + "([/?].*)?").join("|");
    string += ")$";
    return string;
};

const MenuItem = React.forwardRef((props: Props, ref: React.MutableRefObject<HTMLElement>) => {
    const link = (
        <Nav.Link
            ref={ref}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                if ("onClick" in props) props.onClick(e);
            }}
            {...("href" in props && { href: props.href })}
            className={`button ${props.className ?? ""}`}

            {...("target" in props && {
                target: props.target,
            })}
        >
            {typeof props.icon !== "string" && (
                <FontAwesomeIcon className="icon" icon={props.icon} />
            )}
            {typeof props.icon === "string" && (
                <ReactSVG
                    beforeInjection={(svg) => {
                        svg.classList.add("icon");
                    }}
                    src={props.icon}
                />
            )}
            <div className="label">
                {props.label}
            </div>
            {"target" in props && props.target === "_blank" && (
                <FontAwesomeIcon
                    className="outside ml-1"
                    icon={faUpRightFromSquare}
                />
            )}
            {"endIcon" in props && (
                <FontAwesomeIcon className="endIcon" icon={props.endIcon} />
            )}
            {"extraAction" in props && "to" in props.extraAction && (
                <LinkContainer
                    to={props.extraAction.to}
                    {...(props.extraAction.isActiveLocations && {
                        isActive: (match, location) => {
                            const reg = new RegExp(
                                regexBuilder(props.extraAction.isActiveLocations)
                            );
                            return reg.test(location.pathname);
                        },
                    })}
                    {...(props.extraAction.isActive && {
                        isActive: props.extraAction.isActive,
                    })}
                    {...("exact" in props.extraAction && { exact: true })}
                    activeClassName="active"
                >
                    <Nav.Link className="extra-button">
                        {props.extraAction.label}
                        <FontAwesomeIcon
                            className="end-icon"
                            icon={props.extraAction.icon}
                        />
                    </Nav.Link>
                </LinkContainer>
            )}
        </Nav.Link>
    );

    if ("to" in props) {
        return (
            <LinkContainer
                {...(props.isActiveLocations && {
                    isActive: (match, location) => {
                        const reg = new RegExp(regexBuilder(props.isActiveLocations));
                        return reg.test(location.pathname);
                    },
                })}
                {...(props.isActive && { isActive: props.isActive })}
                {...("exact" in props && { exact: true })}
                activeClassName="active"
                to={props.to}
            >
                {link}
            </LinkContainer>
        );
    }

    return link;
});

export default MenuItem;
