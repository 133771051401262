import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ErrorState {
    GeneralErrors: {
        unauthorized: boolean,
    }
}

const ErrorSlice = createSlice({
    name: 'GeneralErrors',
    initialState: {
        unauthorized: false,
    },
    reducers: {
        setUnauthorized: (state, action: PayloadAction<boolean>) => {
            state.unauthorized = action.payload
        },
    },
})

export const { setUnauthorized } = ErrorSlice.actions

export const getUnauthorized = (state: ErrorState) => state.GeneralErrors.unauthorized

export default ErrorSlice