import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF'
}

const AlertTemplate = ({ message, options, style, close }) => {
    var alertType = 'dark';
    var icon = <FontAwesomeIcon icon={faInfo}/>;
    if (options.type === 'info') {
        alertType = 'info'
    } else if (options.type === 'success') {
        alertType = 'success'
        icon = <FontAwesomeIcon icon={faCheck}/>;
    } else if (options.type === 'error') {
        alertType = 'danger'
        icon = <FontAwesomeIcon icon={faExclamationCircle}/>;
    }

    return (
        <div className={`popup-alert alert alert-${alertType}`} style={{...style }}>
            {icon}
            <span style={{ flex: 2, whiteSpace: "pre-wrap" }} className="mx-2">{message}</span>
            <button onClick={close} className="close">
                <span>
                    &times;
                </span>
            </button>
        </div>
    )
}

export default AlertTemplate