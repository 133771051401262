import {FETCH_IP_SETS_START, FETCH_IP_SETS_SUCCESS, FETCH_IP_SETS_ERROR, IP_SETS_INVALIDATE, GEO_FETCH_IP_SETS_START, GEO_FETCH_IP_SETS_SUCCESS, GEO_FETCH_IP_SETS_ERROR, GEO_IP_SETS_INVALIDATE } from './Actions';

const initialState = {
    loading: false,
    items:   [],
    page: 1,
    pageSize: 20,
    total: 0,
    search: "",
    filter: {
        private: false,
        general: false,
        geo: false
    },
    error:   null,
    init: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_IP_SETS_START:
            return {
                ...state,
                error: null,
                loading: true,
                init: true,
                page: action.page,
                pageSize: action.pageSize,
                search: action.search,
                ...(action.filter && {'filter' : action.filter}),
            }
        case FETCH_IP_SETS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.ipSets,
                total: action.total
            }
        case FETCH_IP_SETS_ERROR:
            console.log(action.error);
            return {
                ...state,
                loading: false,
                error:   action.error
            }
        case IP_SETS_INVALIDATE:
            return initialState
        default:
            return state;    
    }   
}