import { stringify } from "qs";
import API from "../../../Utils/API";

export const FETCH_IP_SETS_START   = 'FETCH_IP_SETS_START';
export const FETCH_IP_SETS_SUCCESS = 'FETCH_IP_SETS_SUCCESS';
export const FETCH_IP_SETS_ERROR   = 'FETCH_IP_SETS_ERROR';
export const IP_SETS_INVALIDATE    = 'IP_SETS_INVALIDATE';


function fetchIPSetsStart(page, pageSize, search) {
    return {
        type: FETCH_IP_SETS_START,
        page,
        pageSize,
        search
    }
}

function fetchIPSetsSuccess(ipSets, total) {
    return {
        type: FETCH_IP_SETS_SUCCESS,
        ipSets,
        total
    }
}

function fetchIPSetsError(error) {
    return {
        type: FETCH_IP_SETS_ERROR,
        error
    }
}

export function invalidateIPSets() {
    return {
        type: IP_SETS_INVALIDATE
    }
}

export function fetchIPSets(page, pageSize, search, filter) {
   
    return (dispatch, getState) => {
        const {IPSets} = getState()

        var params = {
            ...(pageSize && {'limit' : pageSize}),
            ...(page && {page}),
        }
    
        if(typeof filter == 'undefined') {
            filter = IPSets.filter
        }
        if(typeof search == 'undefined') {
            search = IPSets.search
        }

        filterItems = [];
    
        if (filter.general) {
            filterItems.push("general")
        }
        if (filter.geo) {
            filterItems.push("geoIP")
        }
        if (filter.geocontinents) {
            filterItems.push("geoIP-continent")
        }
        if (filter.private) {
            filterItems.push("private")
        }
    
        if (search) {
            params.name = search
        }
    
        if (filterItems.length) {
            params.types = filterItems.join(",")
        }

        dispatch(fetchIPSetsStart(page, pageSize, search, filter));
        API.getData("/waf/v2.1/ipset?"+stringify(params), {getTotalCount: true})
            .then(results => {
                results.results.then(IPSets => {
                    dispatch(fetchIPSetsSuccess(IPSets, results.total));
                    return IPSets;
                })
                
            })
            .catch(error =>
                dispatch(fetchIPSetsError(error))
            );
    }
}

export function updateIPSet(ipSet, success, fail) {
    let {uuid, owned, managedIPSet, hasManagedIPSet, meta, ...data} = ipSet;
    return (dispatch) => {

        API.putData("/waf/v2/ipset/"+uuid, data)
            .then(data => {
                dispatch(invalidateIPSets());
                success(data)
            })
            .catch(error => fail(error))
        }
}


export function createIPSet(ipSet, success, fail) {
    return (dispatch) => {
        API.postData("/waf/v2/ipset", ipSet)
            .then(res => {
                success(res)
                dispatch(invalidateIPSets());
            }).catch(error => fail(error))
    }
}

export function deleteIPSet(uuid, success, fail) {
    return (dispatch) => {
        API.deleteData("/waf/v2/ipset/"+uuid)
            .then(res => {
                success(res)
                dispatch(invalidateIPSets())
            }).catch(error => fail(error))
    }
}