import Logo from './Client/Logo';
import {default as DataTrafficTotal} from './DataTraffic/Total'
import ContentSecurityPolicy from './ContentSecurityPolicy'
import {default as DataTrafficPorts} from './DataTraffic/Ports'
import {default as IPSets} from './WAF/IPSets'
import ContentSecurityPolicySetting from './ContentSecurityPolicySetting'
import ContentSecurityPolicySource from './ContentSecurityPolicySource'
import SecurityNotifications from './SecurityNotifications'
import TinySecurityNotifications from './TinySecurityNotifications'

export default {
    Logo,
    DataTrafficTotal,
    DataTrafficPorts,
    IPSets,
    ContentSecurityPolicy,
    ContentSecurityPolicySetting,
    ContentSecurityPolicySource,
    SecurityNotifications,
    TinySecurityNotifications,
};