import React from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import JohnTravolta from "../../../../img/404.gif";

export default NotFound = (props) => {
    return (
        <Container className="pt-4">
            <Row>
                <Col>
                    <h2>404: Page not found</h2>
                    <img src={JohnTravolta} />
                    <Alert variant="info">
                        <span className="mr-3">John can't find your page, try starting from the dashboard?</span>
                        <LinkContainer to="/dashboard">
                            <Button>Go to Dashboard</Button>
                        </LinkContainer>
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};
