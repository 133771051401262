import * as humanizeDuration from "humanize-duration";
import * as moment from "moment";

export const formatWithPrefixMultiplier = (n?: number, suffix: string="", base = 1000) => {
   var ranges = [
      { divider: base**4, suffix: 'T' },
      { divider: base**3, suffix: 'G' },
      { divider: base**2, suffix: 'M' },
      { divider: base**1, suffix: 'k' }
   ];
   for (var i = 0; i < ranges.length; i++) {
      if (n >= ranges[i].divider) {
         return (Math.round((n / ranges[i].divider) *100)/100).toString() + " "+ ranges[i].suffix + suffix;
      }
   }
   return (n ?? 0) + (suffix != "" && " "+suffix)  ;
}

export const formatAsKilo = (n?: number, suffix: string="") => {
   if (n >= 1000) {
      return Math.round(n / 1000).toString() + " k"+ suffix;
   }
   if (suffix != "") {
      return (n ?? 0) + " " + suffix;
   }
   return (n ?? 0) + "";
}

export const formatPrice = (n: number) => {
   return Intl.NumberFormat("nl-NL", {style: 'currency', currency: "EUR"}).format(n)
}

export const truncate = ( str:string, n:number, useWordBoundary?:boolean ) => {
   if (str.length <= n) { return str; }
   const subString = str.substr(0, n-1); // the original check
   return (useWordBoundary 
     ? subString.substr(0, subString.lastIndexOf(" ")) 
     : subString) + "...";
}

export const printDuration = (duration: moment.Duration):string => {
   return humanizeDuration(duration.asMilliseconds(), {conjunction: " and ", serialComma: false, round: true, units: ['y', 'd', 'h', 'm']})
}

export const removeTrailingSlash = (str: string): string => {
   return (str.endsWith('/') ? str.slice(0, -1) : str)
}

export const snakeToCamelCase = (sentence: string) => {
   return sentence  
      .split('_')  
      .map((word, index) => {
         return firstUppercase(word);  
      })  
      .join('');
}

export const snakeToText = (sentence: string) => {
   return sentence  
      .split('_')  
      .map((word, index) => {  
         if (index === 0) {  
            return firstUppercase(word);   
         } else {
            return word
         }
      })  
      .join(' ');
}

export const camelToText = (sentence: string) => {
   return sentence  
      .split(/(?=[A-Z])/)  
      .map((word, index) => {  
         if (index === 0) {  
            return firstUppercase(word);   
         } else {
            return word.toLowerCase()
         }
      })  
      .join(' ');
}

export const firstUppercase = (word: string) => {
   return word.charAt(0).toUpperCase() + word.slice(1);
}

export const toLocaleUTCDateString = (date: Date, locales: string | string[], options: Intl.DateTimeFormatOptions) => {
   const timeDiff = date.getTimezoneOffset() * 60000;
   const adjustedDate = new Date(date.valueOf() + timeDiff);

   return adjustedDate.toLocaleDateString(locales, options);
}

export const createUnitFormatter = (units: string[], base: number) => {
   type formatSplitted = {
      x: string,
      u: string,
      ui: number,
   }
   type formatter = {
      unit: number, // -1 is auto, of its larger then the max index of units, the biggest unit will be used
      minRemainingValue: number,
      minNumberOfNonZeroDecimals: number, // if MinNumberOfNonZeroDecimals = 3, a value of 0.0000234234 wil return 0.0000234. The first 3 non-0 decimals
      maxNumberOfDecimals: number,
      trimTrailingZero: boolean
      reset(): formatter,
      setUnit(unit: string): formatter,
      format(x: number): string,
      formatSplit(x: number): formatSplitted,
   }
   let f: formatter = {
      trimTrailingZero: true,
      minRemainingValue: 1,
      minNumberOfNonZeroDecimals: 4,
      maxNumberOfDecimals: 10,
      unit: -1,
      reset: function(): formatter{
         this.unit = -1;
         this.minRemainingValue = 1;
         this.trimTrailingZero = true;
         this.minNumberOfNonZeroDecimals = 4;
         return this;
      },
      setUnit: function(unit: string): formatter {
         for(let i in units){
            if(units[i] == unit){
               unit = i;
               return
            }
         }
         return this;
      },
      format: function(x: number): string {
         let r = this.formatSplit(x);
         return r.x + " " + r.u;
      },
      formatSplit: function(x: number): formatSplitted {
         let u = this.unit;
         if(u < -1){
            u = -1;
         }
         if(u >= units.length){
            u = units.length-1;
         }
         if (x == 0) {
            return {x: "0", u: units[0], ui: 0}
         }
         let i = 0, n = x || 0;
         while ((u  > -1 || n >= (base * this.minRemainingValue)) && (i + 1 < units.length) && (u == -1 || i < u )) {
            n = n / base;
            i++
         }
         let sig = Math.min(this.maxNumberOfDecimals, Math.max(0, this.minNumberOfNonZeroDecimals - Math.floor(Math.log(n) / Math.log(10))));
         let r =  n.toFixed(sig);
         
         if(this.trimTrailingZero && r.includes('.')){
            r = r.replace(/0+$/, '').replace(/\.$/, '')
         }
         return {x: r, u: units[i]||"-", ui: i}
      },
   };
   f.reset.bind(f);
   f.setUnit.bind(f);
   f.format.bind(f);
   f.formatSplit.bind(f);


   return f;
   
}

export const moneyFormatter = new Intl.NumberFormat('nl-NL', {
   style: 'currency',
   currency: 'EUR',
});

export const decodeHtmlCharCodes = (str: string) => {
   const decodedString = document.createElement("textarea");
   decodedString.innerHTML = str;
   return decodedString.value;
}