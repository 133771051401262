import { FETCH_SECURITYNOTIFICATIONS_START, FETCH_SECURITYNOTIFICATIONS_SUCCESS, FETCH_SECURITYNOTIFICATIONS_ERROR, INVALIDATE_SECURITYNOTIFICATIONS } from '../SecurityNotifications/Actions';

const initialState = {
    loading:       false,
    update:       true,
    securityNotifications: null,
    error:         null,
    page:           1,
    pageSize:       50,
    total:          0,
    filter:          {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INVALIDATE_SECURITYNOTIFICATIONS:
            return {
                ...state,
                securityNotifications: null,
            }
        case FETCH_SECURITYNOTIFICATIONS_START:
            return {
                ...state,
                error:   null,
                loading: true,
                update: false,
                ... (null != action.page && {'page' : action.page}),
                ... (null != action.pageSize && {'pageSize' : action.pageSize}),
                ... (null != action.filter && {'filter' : action.filter}),
            }
        case FETCH_SECURITYNOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                securityNotifications: action.securityNotifications,
                total: action.total
            }    
        case FETCH_SECURITYNOTIFICATIONS_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;    
    }
}