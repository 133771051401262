import { FETCH_TINYSECURITYNOTIFICATIONS_START, FETCH_TINYSECURITYNOTIFICATIONS_SUCCESS, FETCH_TINYSECURITYNOTIFICATIONS_ERROR, INVALIDATE_TINYSECURITYNOTIFICATIONS } from '../TinySecurityNotifications/Actions';

const initialState = {
    loading:       false,
    update:       true,
    securityNotifications: null,
    error:         null,
    page:           1,
    pageSize:       1,
    total:          0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INVALIDATE_TINYSECURITYNOTIFICATIONS:
            return {
                ...state,
                securityNotifications: null,
            }
        case FETCH_TINYSECURITYNOTIFICATIONS_START:
            return {
                ...state,
                error:   null,
                loading: true,
                update: false,
                ... (null != action.page && {'page' : action.page}),
                ... (null != action.pageSize && {'pageSize' : action.pageSize}),
            }
        case FETCH_TINYSECURITYNOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                securityNotifications: action.securityNotifications,
                total: action.total
            }    
        case FETCH_TINYSECURITYNOTIFICATIONS_ERROR:
            console.log(action.error)    ;
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;    
    }
}