import API from "../../Utils/API";

export const FETCH_TINYSECURITYNOTIFICATIONS_START = 'FETCH_TINYSECURITYNOTIFICATIONS_START';
export const FETCH_TINYSECURITYNOTIFICATIONS_SUCCESS = 'FETCH_TINYSECURITYNOTIFICATIONS_SUCCESS';
export const FETCH_TINYSECURITYNOTIFICATIONS_ERROR = 'FETCH_TINYSECURITYNOTIFICATIONS_ERROR';
export const INVALIDATE_TINYSECURITYNOTIFICATIONS = 'INVALIDATE_TINYSECURITYNOTIFICATIONS';

function fetchSecurityNotificationsStart(page, pageSize) {
    return {
        type: FETCH_TINYSECURITYNOTIFICATIONS_START,
        page, 
        pageSize, 
    }
}

function invalidate() {
    return {
        type: INVALIDATE_TINYSECURITYNOTIFICATIONS,
    }
}

function fetchSecurityNotificationsSuccess(securityNotifications, total) {
    return {
        type: FETCH_TINYSECURITYNOTIFICATIONS_SUCCESS,
        securityNotifications,
        total
    }
}

function fetchSecurityNotificationsError(error) {
    return {
        type: FETCH_TINYSECURITYNOTIFICATIONS_ERROR,
        error
    }
}
export function fetchSecurityNotifications(page = 1, limit = 25, update = false, after = null) {
    let u = "false";
    if(update){
        u = "true";
    }
    return (dispatch) => {
        dispatch(fetchSecurityNotificationsStart(page, limit));
        API.getData("/security-notification?page="+page+"&limit="+limit+"&minStatus=WARNING&includeSilenced=false&update="+u, {getTotalCount: true})
            .then(results => {
                results.results.then(securityNotifications => {
                    dispatch(fetchSecurityNotificationsSuccess(securityNotifications, results.total));
                    if(after != null){
                        after(securityNotifications)
                    }
                    return securityNotifications;
                })
            })
            .catch(error => {

                dispatch(fetchSecurityNotificationsError(error.message))
                if(after != null){
                    after([])
                }
            }
            );    
    }
}
export function checkSecurityNotification(id, after) {
    return (dispatch) => {
        API.patchData("/security-notification/"+id+"/check")
            .then(securityNotification => {
                dispatch(invalidate());
                after();
                return securityNotification;
            })
            .catch(error => {
                    dispatch(fetchSecurityNotificationsError(error.message))
                }
            );    
    }
}
export function silenceSecurityNotification(id, after) {
    return (dispatch) => {
        API.patchData("/security-notification/"+id+"/silence")
            .then(securityNotification => {
                dispatch(invalidate());
                after();
                return securityNotification;
            })
            .catch(error =>
                dispatch(fetchSecurityNotificationsError(error.message))
            );    
    }
}

