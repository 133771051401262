import API from "../../Utils/API";
import { fetchContentSecurityPolicySettings } from "../ContentSecurityPolicy/Actions";

export const FETCH_CONTENTSECURITYPOLICYSETTING_START = 'FETCH_CONTENTSECURITYPOLICYSETTING_START';
export const FETCH_CONTENTSECURITYPOLICYSOURCES_START = 'FETCH_CONTENTSECURITYPOLICYSOURCES_START';
export const FETCH_CONTENTSECURITYPOLICYSETTING_SUCCESS = 'FETCH_CONTENTSECURITYPOLICYSETTING_SUCCESS';
export const FETCH_CONTENTSECURITYPOLICYSOURCES_SUCCESS = 'FETCH_CONTENTSECURITYPOLICYSOURCES_SUCCESS';
export const FETCH_CONTENTSECURITYPOLICYSETTING_ERROR = 'FETCH_CONTENTSECURITYPOLICYSETTING_ERROR';
export const FETCH_CONTENTSECURITYPOLICYSOURCES_ERROR = 'FETCH_CONTENTSECURITYPOLICYSOURCES_ERROR';

function fetchContentSecurityPolicySettingStart(websiteUuid, settingUuid) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTING_START,
        websiteUuid,
        settingUuid
    }
}
function fetchContentSecurityPolicySourcesStart(websiteUuid, settingUuid) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSOURCES_START,
        websiteUuid,
        settingUuid
    }
}

function fetchContentSecurityPolicySettingSuccess(contentSecurityPolicySetting) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTING_SUCCESS,
        contentSecurityPolicySetting
    }
}
function fetchContentSecurityPolicySourcesSuccess(contentSecurityPolicySources) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSOURCES_SUCCESS,
        contentSecurityPolicySources
    }
}


function fetchContentSecurityPolicySettingError(error) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSETTING_ERROR,
        error
    }
}
function fetchContentSecurityPolicySourcesError(error) {
    return {
        type: FETCH_CONTENTSECURITYPOLICYSOURCES_ERROR,
        error
    }
}


export function fetchContentSecurityPolicySources(websiteUuid, settingUuid) {
    return (dispatch) => {
        dispatch(fetchContentSecurityPolicySourcesStart(websiteUuid, settingUuid));
        API.getData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+settingUuid+"/source")
            .then(contentSecurityPolicySettings => {
                dispatch(fetchContentSecurityPolicySourcesSuccess(contentSecurityPolicySettings));
                return contentSecurityPolicySettings;
            })
            .catch(error =>
                dispatch(fetchContentSecurityPolicySourcesError(error.message))
            );    
    }
}

export function fetchContentSecurityPolicySetting(websiteUuid, settingUuid) {
    return (dispatch) => {
        dispatch(fetchContentSecurityPolicySettingStart(websiteUuid));
        API.getData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+settingUuid)
            .then(contentSecurityPolicy => {
                dispatch(fetchContentSecurityPolicySettingSuccess(contentSecurityPolicy));
                return contentSecurityPolicy;
            })
            .catch(error =>
                dispatch(fetchContentSecurityPolicySettingError(error.message))
            );    
    }
}

export function editContentSecurityPolicySetting(websiteUuid, data, success, fail) {
    return (dispatch) => {
        let uuid = data.uuid;
        let contentSecurityPolicy = data.contentSecurityPolicy;
        delete data.uuid;
        delete data.contentSecurityPolicy;
        API.putData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+uuid, data)
            .then(res => {
                data.uuid = uuid;
                data.contentSecurityPolicy = contentSecurityPolicy;
                dispatch(fetchContentSecurityPolicySetting(websiteUuid, uuid));
                dispatch(fetchContentSecurityPolicySettings(websiteUuid));
                success();
            })
            .catch(error => {
                data.uuid = uuid;
                data.contentSecurityPolicy = contentSecurityPolicy;
                fail(error);
            });
    }
}

export function createContentSecurityPolicySource(websiteUuid, settingUuid, data, success, fail) {
    let contentSecurityPolicySetting = data.ContentSecurityPolicySetting;

    delete data.ContentSecurityPolicySetting;
    return (dispatch) => {
        API.postData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+settingUuid+"/source", data)
            .then(res => {
                data.ContentSecurityPolicySetting = contentSecurityPolicySetting
                dispatch(fetchContentSecurityPolicySources(websiteUuid, settingUuid));
                success();
            })
            .catch(error => {
                data.ContentSecurityPolicySetting = contentSecurityPolicySetting
                fail(error);
            });
    }
}

export function deleteContentSecurityPolicySource(websiteUuid, settingUuid, uuid, fail) {
    return (dispatch) => {
        API.deleteData("/waf/v2/website/"+websiteUuid+"/content-security-policy/setting/"+settingUuid+"/source/"+uuid)
            .then(() => dispatch(fetchContentSecurityPolicySources(websiteUuid, settingUuid)))
            .catch(e => {
                fail('Unable to delete this setting');
            });
    }
}












