import * as React from "react";
import routes from "./routes";
import { Route, RouteComponentProps, Switch } from "react-router";
import UserMenu from "./UserMenu";
import Loader from "../../Utils/Loader";
import { useGetWhoamiQuery } from "../../../API";

type Props = {
    userMenuOpen?: boolean;
    openSubMenu: (open: boolean) => void
    onBlur?: () => any;
};

type WrapperProps = {
    child?: (React.ComponentType<RouteComponentProps> & React.LazyExoticComponent<(props: any) => JSX.Element>) | JSX.Element;
    openSubMenu: (open: boolean) => void
    routeProps?: RouteComponentProps;
};

const ElementWrapper = (props: WrapperProps) => {
    if (props.child) {
        props.openSubMenu(true)
        return (
            <>{"props" in props.child ? props.child : <props.child {...props.routeProps} />}</>
        );
    } else if (!props.child) {
        props.openSubMenu(false)
        return <></>;
    }
    return <></>;
};

const SubMenu = React.forwardRef((props: Props, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { userMenuOpen, openSubMenu } = props;

    const { data: user, isLoading, error } = useGetWhoamiQuery();

    if (isLoading) return <Loader />;

    if (error) return <div>Could not fetch user.</div>;

    const AsyncAdminMenu = React.lazy(() => import("../../Pages/Admin/Sidebar"));

    return (
        <div className={"sub-menu" + (userMenuOpen ? " user-open" : "")}>
            <React.Suspense fallback={<Loader />}>
            <Switch>
                {user.admin && <Route 
                    path="/ca2/admin" 
                    render={(props) => (
                        <ElementWrapper
                            openSubMenu={openSubMenu}
                            routeProps={props}
                            child={AsyncAdminMenu}
                            {...(!AsyncAdminMenu && userMenuOpen && { child: <></> })}
                        />
                    )} />
                }
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <ElementWrapper
                                openSubMenu={openSubMenu}
                                routeProps={props}
                                child={route.sidebar}
                                {...(!route.sidebar && userMenuOpen && { child: <></> })}
                            />
                        )}
                    />
                ))}
                <Route
                    path="*"
                    render={(props) => {
                        return <ElementWrapper openSubMenu={openSubMenu} />;
                    }}
                />
            </Switch>
            </React.Suspense>
            {props.userMenuOpen && <UserMenu ref={ref} onBlur={props.onBlur} />}
        </div>
    );
});

export default SubMenu;
